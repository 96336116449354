import foto1 from '../../shared/assets/imgs/galeria/1.jpg'
import foto2 from '../../shared/assets/imgs/galeria/2.jpg'
import foto3 from '../../shared/assets/imgs/galeria/3.jpg'
import foto4 from '../../shared/assets/imgs/galeria/4.jpg'

export const photos = [
    { src: foto1, alt: 'Foto Cora & Theo ensaio 2023' },
    { src: foto2, alt: 'Foto Cora & Theo ensaio 2023' },
    { src: foto3, alt: 'Foto Cora & Theo ensaio 2023' },
    { src: foto4, alt: 'Foto Cora & Theo ensaio 2023' }
];
